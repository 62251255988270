export const desklyColors = {
    primary: '#1946b9',
    secondary: '#1946b9',
    hover: '#1971d6',
    active: '#1A3D8E',
    accent: '#dbc3f4',
    accentLight: '#ebd9fe',
    accentLighter: '#f3e8fe',
    background: '#efebe7',
    backgroundLight: '#f5f2ef',
    backgroundLighter: '#ffffff',
};

export const customColors = {
    accent: '#b0b0b0',
    accentLight: '#c7c7c7',
    accentLighter: '#dfdfdf',
    background: '#ebebeb',
    backgroundLight: '#ffffff',
    backgroundLighter: '#f5f5f5',
};

export const colorsGlobal = {
    black: '#2c2c2c',
    blackLight: '#454545',
    white: '#ffffff',
    gray: '#b0b0b0',
    grayLight: '#e6e6e6',
    warning: '#fee9d9',
    error: '#ee5d5d',
    errorDark: '#cb4f4f',
    success: '#1daa99',
    inputIcon: '#6b7280',
};

export const chartColors = {
    resources: {
        seat: '#C8DFA0',
        green1: '#AEDF9F',
        green2: '#A0DFAA',
        green3: '#A0DFC3',
        blue: '#A0DFDC',
        zone: '#9FC8DF',
        lila1: '#9FAEDF',
        lila2: '#A99FDF',
        rosa1: '#C39FDF',
        rosa2: '#DC9FDF',
        meetingRoom: '#DE9FC8',
        red: '#DE9FAE',
        orange1: '#DFA99F',
        orange2: '#DFC39F',
        yellow: '#DEDC9F',
    },
    status: {
        office: '#707682',
        mobile: '#A1A9BC',
        absent: '#D4E0F9',
    },
};
export const primitives = {
    desklyPrimaryColorPalette: {
        50: '#eef7ff',
        100: '#daecff',
        200: '#bddeff',
        300: '#8fcaff',
        400: '#5aacff',
        500: '#3489fd',
        600: '#1d6af3',
        700: '#1654df',
        800: '#1946b9',
        900: '#1a3d8e',
        950: '#152756',
    },
    zinc: {
        50: '#FAFAFA',
        100: '#F4F4F5',
        200: '#E4E4E7',
        300: '#D4D4D8',
        400: '#A1A1AA',
        500: '#71717A',
        600: '#52525B',
        700: '#3F3F46',
        800: '#27272A',
        900: '#18181B',
        950: '#09090B',
    },
    blue: {
        50: '#F5F9FF',
        100: '#D0E1FD',
        200: '#ABC9FB',
        300: '#85B2F9',
        400: '#609AF8',
        500: '#3B82F6',
        600: '#326FD1',
        700: '#295BAC',
        800: '#204887',
        900: '#183462',
    },
    green: {
        50: '#F4FCF7',
        100: '#CAF1D8',
        200: '#A0E6BA',
        300: '#76DB9B',
        400: '#4CD07D',
        500: '#22C55E',
        600: '#1DA750',
        700: '#188A42',
        800: '#136C34',
        900: '#0E4F26',
    },
    yellow: {
        50: '#FEFBF3',
        100: '#FAEDC4',
        200: '#F6DE95',
        300: '#F2D066',
        400: '#EEC137',
        500: '#EAB308',
        600: '#C79807',
        700: '#A47D06',
        800: '#816204',
        900: '#5E4803',
    },
    orange: {
        50: '#FFF8F3',
        100: '#FEDDC7',
        200: '#FCC39B',
        300: '#FBA86F',
        400: '#FA8E42',
        500: '#F97316',
        600: '#D46213',
        700: '#AE510F',
        800: '#893F0C',
        900: '#642E09',
    },
    red: {
        50: '#FFF5F5',
        100: '#FFD0CE',
        200: '#FFACA7',
        300: '#FF8780',
        400: '#FF6259',
        500: '#FF3D32',
        600: '#D9342B',
        700: '#B32B23',
        800: '#8C221C',
        900: '#661814',
    },
};

export default {
    data() {
        return {
            desklyColors,
            customColors,
            colorsGlobal,
            chartColors,
            primitives,
        };
    },
};
