import debounce from 'lodash/debounce';

const debounceTime = 200;

export function addMatomoTracking() {
    window.navigation.addEventListener(
        'navigate',
        debounce(() => {
            const _paq = window._paq;
            if (!_paq || !(_paq instanceof Array)) {
                return;
            }
            _paq.push(['setCustomUrl', window.location.href]);
            _paq.push(['setDocumentTitle', document.title]);
            _paq.push(['trackPageView']);
        }, debounceTime)
    );
}
