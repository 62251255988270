export default class NotificationChangeStatusForm {
    static STATUS_DEFAULT = 0;
    static STATUS_READ = 1;
    static STATUS_ARCHIVED = 2;

    /**
     * @param {number} status
     */
    constructor(status) {
        this.status = status;
    }

    static markAsRead() {
        return new NotificationChangeStatusForm(NotificationChangeStatusForm.STATUS_READ);
    }

    static markAsArchived() {
        return new NotificationChangeStatusForm(NotificationChangeStatusForm.STATUS_ARCHIVED);
    }
}
