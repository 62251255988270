import {getFilterQueryParams} from './dataTableFilter';
import {getSortQueryParam} from './dataTableSort';

/**
 * Creates a new instance of {@link URLSearchParams}
 * and sets pagination, filter and sort parameters.
 * @param {Number} limit
 * @param {Number} offset
 * @param {import('primevue/datatable').DataTableFilterMeta} filter
 * @param {Array<import('primevue/datatable').DataTableSortMeta>} sort
 * @return {URLSearchParams}
 */
export const getSearchParamsByTableParams = ({limit, offset, filter, sort}) => {
    const searchParams = new URLSearchParams();

    addFilterToSearchParams(searchParams, filter);
    addSortToSearchParams(searchParams, sort);
    addPaginationToSearchParams(searchParams, offset, limit);

    return searchParams;
};

/**
 * Applies sort parameters to {@link searchParams}
 * @param {URLSearchParams} searchParams
 * @param {Array<import('primevue/datatable').DataTableSortMeta>} sort
 * @return {URLSearchParams}
 */
export const addSortToSearchParams = (searchParams, sort) => {
    getSortQueryParam(sort).forEach(param => {
        if (param) {
            searchParams.append('order[]', param);
        }
    });

    return searchParams;
};

/**
 * Applies filter parameters to {@link searchParams}
 * @param {URLSearchParams} searchParams
 * @param {import('primevue/datatable').DataTableFilterMeta} filter
 * @param {URLSearchParams} searchParams
 */
export const addFilterToSearchParams = (searchParams, filter) => {
    if (filter) {
        const filterQueryParams = getFilterQueryParams(filter);
        filterQueryParams.forEach(param => {
            if (param instanceof Array) {
                param.forEach(({key, value}) => {
                    searchParams.append(key + '[]', value);
                });
            } else {
                searchParams.append(param.key, param.value);
            }
        });
    }

    return searchParams;
};

/**
 * Applies pagination parameters to {@link searchParams}
 * @param {URLSearchParams} searchParams
 * @param {Number} offset
 * @param {Number} limit
 * @param {URLSearchParams} searchParams
 */
export const addPaginationToSearchParams = (searchParams, offset, limit) => {
    if (offset) {
        searchParams.append('offset', offset);
    }

    if (limit) {
        searchParams.append('limit', limit);
    }

    return searchParams;
};
