import API from '../api';
import NotificationView from '../models/view/NotificationView';
import NotificationChangeStatusForm from '../models/form/NotificationChangeStatusForm';
import {getSearchParamsByTableParams} from '../../admin/components/tables/dataTable/dataTableQueryHelper';

export default () => ({
    read() {
        return API.get('/api/notifications')
            .then(API.handle)
            .catch(API.error)
            .then(items => items.map(rawJson => new NotificationView(rawJson)));
    },
    markAsRead(notificationId) {
        return API.put(
            `/api/notifications/change/${notificationId}`,
            NotificationChangeStatusForm.markAsRead()
        )
            .then(API.handle)
            .catch(API.error);
    },
    markAsArchived(notificationId) {
        return API.put(
            `/api/notifications/change/${notificationId}`,
            NotificationChangeStatusForm.markAsArchived()
        )
            .then(API.handle)
            .catch(API.error);
    },
    async filter(tableParams) {
        const searchParams = getSearchParamsByTableParams(tableParams);
        return API.get(`/api/notifications/by-account?${searchParams.toString()}`)
            .then(API.handle)
            .catch(API.error)
            .then(result => ({
                ...result,
                data: result.data.map(singleRaw => new NotificationView(singleRaw)),
            }));
    },
    /**
     * @param {NotificationForm} notification
     */
    add(notification) {
        return API.postJSON('/api/notifications/admin/create', notification)
            .then(API.handle)
            .catch(API.error);
    },
});
