<template>
    <svg fill="none" height="240" width="323" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#a)">
            <path
                d="M176.458 52.834a194.003 194.003 0 0 0-21.079-4.474 304.977 304.977 0 0 0-35.155-3.378 366.606 366.606 0 0 0-26.837-.11 314.646 314.646 0 0 0-34.321 2.9 201.816 201.816 0 0 0-24.975 5.063C14.95 58.127 4.406 65.22 4.406 72.804c0 7.584 10.543 14.677 29.685 19.97 13.462 3.724 30.06 6.262 48.146 7.426 7.464.485 15.181.73 23.038.73 7.881 0 15.624-.249 23.112-.734 16.459-1.063 31.686-3.265 44.431-6.466a151.851 151.851 0 0 0 2.744-.711 120.43 120.43 0 0 1 .948-.262c.284-.077.565-.158.847-.236.562-.161 1.118-.32 1.665-.485l.071-.019c.475-.142.941-.284 1.403-.427.378-.12.753-.236 1.125-.355l.805-.262c.194-.065.385-.126.572-.191a50.144 50.144 0 0 0 1.08-.369c.242-.08.478-.165.714-.249.256-.09.511-.184.763-.274.211-.075.421-.152.628-.23.174-.068.346-.132.52-.197.475-.178.941-.362 1.4-.543.23-.094.459-.184.685-.278a87.658 87.658 0 0 0 1.736-.734c.169-.078.34-.152.505-.223.126-.058.249-.113.375-.172a52.57 52.57 0 0 0 1.235-.576 50.149 50.149 0 0 0 3.404-1.784c6.612-3.785 10.1-7.985 10.1-12.35 0-7.584-10.543-14.677-29.685-19.97Zm-.175 39.32c-13.478 3.727-30.121 6.265-48.255 7.42-7.374.468-14.998.711-22.753.711-7.73 0-15.328-.24-22.68-.708-18.162-1.151-34.832-3.69-48.33-7.423-18.838-5.212-29.212-12.082-29.212-19.35a7.796 7.796 0 0 1 .239-1.904c1.649-6.556 11.79-12.692 28.974-17.445a201.695 201.695 0 0 1 24.784-5.033 315.549 315.549 0 0 1 34.528-2.91 368.804 368.804 0 0 1 26.448.107 304.33 304.33 0 0 1 35.359 3.394 193.606 193.606 0 0 1 20.898 4.442c17.18 4.753 27.319 10.886 28.971 17.439.16.624.242 1.266.242 1.91 0 7.268-10.374 14.138-29.213 19.35Z"
                fill="#1946B9"
            />
            <path
                d="M205.959 73.098c0 4.32-3.56 8.406-9.916 12.056a52.691 52.691 0 0 1-4.371 2.237 6.966 6.966 0 0 1-.268.123c-.126.058-.249.113-.375.172a87.648 87.648 0 0 1-2.241.957c-.226.093-.455.184-.685.278-.459.18-.925.365-1.4.543-.174.064-.346.129-.521.197-.206.077-.416.155-.627.23-.252.09-.507.184-.763.274-.236.084-.472.168-.714.25-.103.038-.21.073-.317.109-.252.087-.507.175-.763.259-.187.064-.378.126-.572.19-.265.088-.534.175-.805.262-.372.12-.747.236-1.125.356-.462.142-.928.284-1.403.427l-.071.019c-.547.165-1.103.323-1.665.485-.282.078-.563.158-.848.236a131.007 131.007 0 0 0-.947.262 236.861 236.861 0 0 1-2.745.711c-12.398 3.097-27.609 5.354-44.443 6.443-7.374.476-15.062.728-22.96.728-7.97 0-15.722-.256-23.155-.741-44.37-2.893-77.39-13.911-77.39-27.063.003-.8.125-1.595.361-2.36l.062.162c4.613 12.117 37.354 21.91 79.835 24.299 6.508.368 13.248.56 20.147.56 6.929 0 13.695-.195 20.229-.563 42.446-2.4 75.154-12.195 79.751-24.302.077-.195.145-.389.203-.583.328.893.498 1.836.502 2.787Z"
                fill="#2C2C2C"
            />
            <path
                d="M34.042 69.618c-5.38 0-11.099-1.158-11.099-3.303 0-2.146 5.719-3.303 11.1-3.303 5.38 0 11.098 1.157 11.098 3.303 0 2.145-5.718 3.303-11.099 3.303Zm0-5.96c-6.16 0-10.452 1.4-10.452 2.657 0 1.256 4.293 2.656 10.452 2.656 6.16 0 10.453-1.4 10.453-2.656 0-1.257-4.293-2.657-10.453-2.657ZM56.996 84.813c-5.38 0-11.099-1.157-11.099-3.303 0-2.145 5.719-3.303 11.1-3.303 5.38 0 11.098 1.158 11.098 3.303s-5.718 3.303-11.099 3.303Zm0-5.96c-6.16 0-10.452 1.4-10.452 2.657 0 1.256 4.293 2.656 10.452 2.656 6.16 0 10.453-1.4 10.453-2.656 0-1.256-4.293-2.656-10.453-2.656ZM176.293 69.618c-5.381 0-11.099-1.158-11.099-3.303 0-2.146 5.718-3.303 11.099-3.303 5.38 0 11.099 1.157 11.099 3.303 0 2.145-5.719 3.303-11.099 3.303Zm0-5.96c-6.16 0-10.453 1.4-10.453 2.657 0 1.256 4.293 2.656 10.453 2.656 6.16 0 10.452-1.4 10.452-2.656 0-1.257-4.292-2.657-10.452-2.657ZM153.339 84.813c-5.381 0-11.099-1.157-11.099-3.303 0-2.145 5.718-3.303 11.099-3.303 5.38 0 11.099 1.158 11.099 3.303s-5.719 3.303-11.099 3.303Zm0-5.96c-6.16 0-10.453 1.4-10.453 2.657 0 1.256 4.293 2.656 10.453 2.656 6.159 0 10.452-1.4 10.452-2.656 0-1.256-4.293-2.656-10.452-2.656ZM105.168 91.926c-5.38 0-11.1-1.157-11.1-3.303 0-2.145 5.72-3.303 11.1-3.303s11.099 1.158 11.099 3.303c0 2.146-5.719 3.303-11.099 3.303Zm0-5.96c-6.16 0-10.453 1.401-10.453 2.657 0 1.257 4.293 2.657 10.453 2.657 6.159 0 10.452-1.4 10.452-2.657 0-1.256-4.293-2.656-10.452-2.656Z"
                fill="#1946B9"
            />
            <path
                d="M90.403 160.74a.647.647 0 0 1-.647-.647v-38.149a.646.646 0 0 1 1.293 0v38.149a.649.649 0 0 1-.646.647ZM80.704 195.01a.644.644 0 0 1-.647-.647V166.56a.649.649 0 0 1 .647-.647.647.647 0 0 1 .647.647v27.803a.645.645 0 0 1-.647.647ZM105.598 172.055a.645.645 0 0 1-.647-.646V133.26c0-.172.068-.336.19-.457a.642.642 0 0 1 .914 0 .645.645 0 0 1 .189.457v38.149a.647.647 0 0 1-.646.646ZM96.222 199.536a.647.647 0 0 1-.646-.647v-15.518a.647.647 0 0 1 1.293 0v15.518a.649.649 0 0 1-.647.647ZM109.477 120.006a.644.644 0 0 1-.646-.647v-15.518a.645.645 0 1 1 1.293 0v15.518a.645.645 0 0 1-.647.647ZM77.148 126.795a.644.644 0 0 1-.647-.647V110.63a.647.647 0 0 1 1.293 0v15.518a.645.645 0 0 1-.646.647ZM133.401 128.411a.647.647 0 0 1-.646-.647v-15.518a.647.647 0 0 1 1.293 0v15.518a.646.646 0 0 1-.647.647ZM132.108 176.906a.644.644 0 0 1-.646-.647v-18.751a.645.645 0 1 1 1.293 0v18.751a.642.642 0 0 1-.19.457.642.642 0 0 1-.457.19ZM119.176 193.394a.648.648 0 0 1-.646-.647v-27.804a.649.649 0 0 1 .646-.646.645.645 0 0 1 .647.646v27.804a.646.646 0 0 1-.647.647ZM121.762 145.869a.649.649 0 0 1-.646-.647v-38.149a.647.647 0 0 1 1.293 0v38.149a.65.65 0 0 1-.647.647Z"
                fill="#B0B0B0"
            />
            <path
                d="M201.033 227.048c.925-.775-.326-3.793-2.794-6.741-2.468-2.948-5.219-4.709-6.145-3.935-.925.775.326 3.793 2.794 6.741 2.468 2.948 5.219 4.71 6.145 3.935Z"
                fill="#111928"
            />
            <path
                d="M194.963 223.696c2.432-7.295-1.511-15.181-8.806-17.612-7.295-2.432-15.18 1.511-17.612 8.806-2.432 7.295 1.511 15.18 8.806 17.612 7.295 2.431 15.18-1.511 17.612-8.806Z"
                fill="#111928"
            />
            <path
                d="M187.647 230.22h-4.229v7.578h4.229v-7.578ZM179.188 230.22h-4.23v7.578h4.23v-7.578Z"
                fill="#111928"
            />
            <path
                d="M184.123 239.209c1.946 0 3.525-.592 3.525-1.322 0-.73-1.579-1.322-3.525-1.322-1.947 0-3.525.592-3.525 1.322 0 .73 1.578 1.322 3.525 1.322ZM175.663 239.032c1.946 0 3.525-.591 3.525-1.321 0-.731-1.579-1.322-3.525-1.322-1.947 0-3.525.591-3.525 1.322 0 .73 1.578 1.321 3.525 1.321Z"
                fill="#111928"
            />
            <path
                d="M177.382 201.79c1.243-5.007 6.731-7.953 12.258-6.581 5.526 1.372 8.999 6.543 7.755 11.55-1.243 5.007-5.368 5.023-10.894 3.651-5.527-1.372-10.362-3.613-9.119-8.62Z"
                fill="#B0B0B0"
            />
            <path
                d="M172.362 215.815c.517-1.09-1.88-3.31-5.354-4.958-3.474-1.647-6.709-2.099-7.226-1.008-.518 1.09 1.879 3.31 5.353 4.958 3.474 1.647 6.71 2.099 7.227 1.008Z"
                fill="#111928"
            />
            <path
                d="M179.673 220.997a4.642 4.642 0 1 0 0-9.285 4.642 4.642 0 0 0 0 9.285Z"
                fill="#fff"
            />
            <path
                d="M177.765 216.238a1.547 1.547 0 1 0-.001-3.095 1.547 1.547 0 0 0 .001 3.095Z"
                fill="#111928"
            />
            <path
                d="M179.633 227.987a1.94 1.94 0 1 0 0-3.878 1.94 1.94 0 0 0 0 3.878Z"
                fill="#fff"
            />
            <path
                d="M221.509 239.302h-76.297a.323.323 0 1 1 0-.647h76.297a.323.323 0 1 1 0 .647Z"
                fill="#2C2C2C"
            />
            <ellipse cx="107.145" cy="47.556" fill="#1946B9" rx="48.241" ry="17.231" />
            <path
                d="M155.385 49.013c-15.072-2.406-32.236-3.69-50.111-3.69-16.358 0-32.12 1.074-46.224 3.098a48.171 48.171 0 0 1 96.33-.061c.002.22.005.436.005.653Z"
                fill="#1946B9"
            />
            <path
                d="M114.634 2.058A48.184 48.184 0 0 0 60.02 48.363c-.01.217-.013.433-.017.65 2.419 3.032 8.537 8.02 12.993 7.59.544-11.242 5-31.074 12.608-39.369a48.178 48.178 0 0 1 29.03-15.176Z"
                fill="#fff"
            />
            <g filter="url(#b)">
                <path
                    clip-rule="evenodd"
                    d="M204.206 72.311c-22.127 0-40.064 17.938-40.064 40.064v31.93c0 18.515 12.559 34.096 29.623 38.69l-4.829 16.229c-.463 1.555 1.102 2.941 2.59 2.294l39.41-17.149h47.715c22.126 0 40.064-17.937 40.064-40.064v-31.93c0-22.126-17.938-40.064-40.064-40.064h-74.445Z"
                    fill="#fff"
                    fill-rule="evenodd"
                />
                <path
                    d="m193.765 182.995.228.068.07-.235-.236-.064-.062.231Zm-4.829 16.229-.229-.068.229.068Zm2.59 2.294-.096-.219.096.219Zm39.41-17.149v-.239h-.05l-.045.02.095.219Zm-66.555-71.994c0-21.995 17.83-39.825 39.825-39.825v-.477c-22.258 0-40.302 18.044-40.302 40.302h.477Zm0 31.93v-31.93h-.477v31.93h.477Zm29.446 38.459c-16.963-4.566-29.446-20.055-29.446-38.459h-.477c0 18.625 12.634 34.299 29.799 38.92l.124-.461Zm-.291.163-4.829 16.229.457.136 4.829-16.229-.457-.136Zm-4.829 16.229c-.52 1.749 1.241 3.308 2.914 2.58l-.191-.437c-1.301.566-2.67-.646-2.266-2.007l-.457-.136Zm2.914 2.58 39.41-17.148-.19-.438-39.411 17.149.191.437Zm87.03-17.606h-47.715v.477h47.715v-.477Zm39.825-39.825c0 21.995-17.83 39.825-39.825 39.825v.477c22.258 0 40.302-18.044 40.302-40.302h-.477Zm0-31.93v31.93h.477v-31.93h-.477ZM278.651 72.55c21.995 0 39.825 17.83 39.825 39.825h.477c0-22.258-18.044-40.302-40.302-40.302v.477Zm-74.445 0h74.445v-.477h-74.445v.477Z"
                    fill="#B0B0B0"
                />
            </g>
            <path
                d="M280.858 169.847a3.436 3.436 0 0 1 0-1.005l.273-1.176c.262-1.128-.416-2.285-1.529-2.691l.126-.541a.278.278 0 0 0-.211-.324.271.271 0 0 0-.33.193l-.128.552c-1.173-.147-2.289.576-2.551 1.704l-.273 1.175a3.41 3.41 0 0 1-.442.898.789.789 0 0 0-.096.747.859.859 0 0 0 .597.504l.676.164c-.02.529.345 1.027.898 1.161.553.134 1.11-.141 1.313-.625l.664.161a.848.848 0 0 0 .755-.177.75.75 0 0 0 .258-.72Zm-2.87.844a.608.608 0 0 1-.456-.495l1.081.262a.611.611 0 0 1-.625.233Zm2.226-.528a.289.289 0 0 1-.249.064l-3.539-.858a.26.26 0 0 1-.194-.171.24.24 0 0 1 .03-.241 3.774 3.774 0 0 0 .52-1.066l.003-.011.276-1.187c.216-.929 1.18-1.502 2.15-1.267.971.235 1.583 1.178 1.367 2.106l-.276 1.187-.002.012c-.006.024-.105.62-.004 1.191.017.091-.004.185-.082.241Z"
                fill="#DFE4EA"
            />
            <path
                d="M288.318 167.984a4.79 4.79 0 0 1-.792-1.128l-.629-1.533c-.604-1.472-2.254-2.237-3.784-1.817l-.289-.706a.374.374 0 0 0-.484-.198.37.37 0 0 0-.207.477l.296.72c-1.392.757-2.035 2.446-1.431 3.917l.629 1.534c.031.076.226.73.226 1.355.007.373.183.713.485.913.301.2.7.236 1.046.097l.864-.348a1.562 1.562 0 0 0 1.891.596c.707-.285 1.096-1.031.936-1.735l.848-.341c.345-.139.606-.441.681-.793a1.052 1.052 0 0 0-.286-1.01Zm-2.456 3.204a.811.811 0 0 1-.886-.197l1.382-.556c-.003.305-.182.627-.496.753Zm2.004-2.343a.377.377 0 0 1-.22.267l-4.523 1.822a.343.343 0 0 1-.346-.04.337.337 0 0 1-.157-.294c.006-.788-.261-1.574-.274-1.604l-.006-.016-.636-1.548c-.496-1.211.1-2.613 1.341-3.113 1.24-.499 2.648.077 3.145 1.288l.635 1.549.006.015c.013.031.375.779.934 1.339.091.089.142.212.101.335Z"
                fill="#8899A8"
            />
            <path
                d="M299.242 151.907c1.121 1.036 1.156 2.731.079 3.787-1.078 1.056-2.86 1.072-3.981.037-1.121-1.036-1.156-2.731-.079-3.787 1.078-1.056 2.86-1.073 3.981-.037Z"
                fill="#0B76B7"
            />
            <path
                clip-rule="evenodd"
                d="M301.07 156.1a8.773 8.773 0 0 0 2.064 1.216c.623.23 1.045.735 1.182 1.361a1.766 1.766 0 0 1-.5 1.65l-1.09 1.067a2.434 2.434 0 0 1-.219 3.254c-.908.889-2.347.98-3.412.304l-1.109 1.087a1.981 1.981 0 0 1-1.719.524 1.99 1.99 0 0 1-1.423-1.084c-.453-.956-1.233-1.831-1.338-1.928l-2.101-1.941c-2.016-1.863-2.224-4.858-.579-6.91l-.987-.912a.594.594 0 0 1-.018-.862.67.67 0 0 1 .906-.007l.966.892a5.465 5.465 0 0 1 2.059-.979 3.35 3.35 0 0 0-.115 1.346 4.1 4.1 0 0 0-1.464.922c-1.594 1.561-1.519 4.088.14 5.621l2.142 1.979c.043.039 1.032 1.069 1.593 2.278.066.176.254.312.461.349a.597.597 0 0 0 .573-.162l5.81-5.692a.594.594 0 0 0 .153-.55c-.025-.215-.193-.37-.401-.447-1.287-.498-2.398-1.409-2.44-1.448l-.549-.507a3.582 3.582 0 0 0 .84-.952l.575.531Zm-1.026 7.962c.52.211 1.136.127 1.539-.268.404-.395.452-1.003.237-1.471l-1.776 1.739Z"
                fill="#2C2C2C"
                fill-rule="evenodd"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path d="M0 0h322.419v238.476H0z" fill="#fff" transform="translate(0 .74)" />
            </clipPath>
            <filter
                id="b"
                color-interpolation-filters="sRGB"
                filterUnits="userSpaceOnUse"
                height="137.478"
                width="162.681"
                x="160.088"
                y="72.073"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy="3.816" />
                <feGaussianBlur stdDeviation="1.908" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_3373_1352" />
                <feBlend in="SourceGraphic" in2="effect1_dropShadow_3373_1352" result="shape" />
            </filter>
        </defs>
    </svg>
</template>
