<template>
    <div
        v-if="hasUnreadNotifications"
        class="absolute w-2.5 h-2.5 bg-red-500 rounded-full"
        style="top: -4px; right: -4px"
    ></div>
</template>

<script setup>
import {storeToRefs} from 'pinia';
import {useNotificationStore} from '../../../../stores/notificationStore';

const {hasUnreadNotifications} = storeToRefs(useNotificationStore());
</script>
