export const getSortQueryParam = sort => {
    if (sort instanceof Array) {
        return sort.map(serializeSortElement).filter(Boolean);
    }

    return [serializeSortElement(sort)];
};

const serializeSortElement = sortElement => {
    const sortDirectionPrefix =
        sortElement.order === 1 ? '' : sortElement.order === -1 ? '^' : null;

    if (typeof sortElement.field !== 'string' || sortDirectionPrefix === null) {
        return null;
    }

    return `${sortDirectionPrefix}${sortElement.field}`;
};
