const SAVE_USER_FLAGS = 'SAVE_USER_FLAGS',
    DISABLE_LOADING = 'DISABLE_LOADING';

const state = () => ({
    userFlags: null,
    loading: true,
});

const actions = {
    /**
     *
     * @param {function} commit commits mutation
     * @param {Object} state modules source of truth
     * @param {Object} userFlags user flags object
     */
    saveUserFlags({commit, state}, userFlags) {
        commit(SAVE_USER_FLAGS, userFlags);

        if (state.loading === true) {
            commit(DISABLE_LOADING);
        }
    },
    /**
     *
     * @param {function} commit commits mutation
     */
    disableLoadingState({commit}) {
        commit(DISABLE_LOADING);
    },
};

const mutations = {
    /**
     *
     * @param {Object} state modules source of truth
     * @param {Object} userFlags user flags object
     */
    [SAVE_USER_FLAGS](state, userFlags) {
        state.userFlags = userFlags;
    },
    /**
     *
     * @param {Object} state modules source of truth
     */
    [DISABLE_LOADING](state) {
        state.loading = false;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
