<template>
    <div class="flex items-center">
        <close-icon class="icon-close" />
    </div>
</template>

<script>
import CloseIcon from '../icons/CloseIcon.vue';

export default {
    components: {CloseIcon},
};
</script>
