export default class NotificationView {
    constructor(json) {
        this.id = json.id;
        this.title = json.title;
        this.message = json.message;
        this.type = json.type;
        this.archivedAt = json.archivedAt;
        this.createdAt = json.createdAt;
        this.readAt = json.readAt;
        this.linkText = json.linkText;
        this.linkUrl = json.linkUrl;
    }

    /**
     *
     * @param {NotificationView} notification
     * @returns {CustomMessageOptions}
     */
    static toToastMessage(notification) {
        return {
            detail: notification.message,
            severity: notification.type,
            summary: notification.title,
            customData: {
                date: notification.createdAt,
                link:
                    notification.linkText && notification.linkUrl
                        ? {
                              label: notification.linkText,
                              url: notification.linkUrl,
                          }
                        : null,
            },
        };
    }
}
