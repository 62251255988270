import global from './global.js';
import datatable from './datatable';
import paginator from './paginator';
import inputNumber from './inputNumber';
import toast from './toast';
import menu from './menu';
import password from './password';
import inputText from './inputText';
import dropdown from './dropdown';
import checkbox from './checkbox';
import divider from './divider';
import button from './button';
import radioButton from './radioButton';
import inputGroup from './inputGroup';
import inputGroupAddon from './inputGroupAddon';

export default {
    global,
    datatable,
    paginator,
    toast,
    menu,
    inputNumber,
    password,
    inputText,
    dropdown,
    checkbox,
    divider,
    radioButton,
    inputGroup,
    inputGroupAddon,
    button,
};
